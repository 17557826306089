import React from "react"
import SEO from "../components/seo"
import Header from "../components/headers/secondary/Secondary";
import Navigator from "../components/navigator/DefaultNavbar";
import Footer from "../components/footer/DefaultFooter";
import Terms from "../components/terms/Terms";
import CookieConsent from "../components/cookieConsent/CookieConsent"

const IndexPage = () => (
  <>
    <SEO
      title="Terms & Conditions"
      description="Create your backend infrastructure up to 80% faster. Automatically create the project boilerplate in Node.js with TypeScript and deploy it to AWS within a few minutes." />
    <Navigator
      useSpaNav={true}
    />
    <Header
      big={true}
      title="Terms & Conditions"
    />
    <Terms />
    <Footer />
    <CookieConsent />
  </>
)

export default IndexPage
